import {Component, Inject} from '@angular/core';

import {select, Store} from '@ngrx/store';
import {ActivatedRoute} from "@angular/router";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {BottomSheetComponent} from "../bottom-sheet/bottom-sheet.component";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

import * as estimateReducers from '../../state/estimate.reducer';
import * as estimateActions from '../../state/estimate.actions';
import {Observable} from "rxjs";
import {Module} from "../../models";
import {TranslateService} from "@ngx-translate/core";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {ApplicationCost} from "../../models/applicationCost.model";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ArrayValidators} from "@ngnizer/ux";
import {filter} from "rxjs/operators";
import {OfferRequest} from "../../models/offer_request.model";


@UntilDestroy()
@Component({
  selector: 'app-application-details-card',
  templateUrl: './custom-app-card.component.html',
  styleUrls: ['./custom-app-card.component.scss']
})
export class CustomAppCardComponent {

  appName = 'custom';

  isLoading$: Observable<boolean>;
  modules$: Observable<Module[]>;
  customAppCost$: Observable<ApplicationCost>;
  isOfferComputed$: Observable<boolean>;

  modules: Module[];
  customAppCost: ApplicationCost;

  isWeb = false;
  deconstructForm: FormGroup;
  showValidations = false;

  constructor(
    @Inject('environment') private environment: any,
    private store: Store<any>,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private fb: FormBuilder,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {

    this.deconstructForm = this.fb.group({
      description: ['', [Validators.required, Validators.minLength(100)]],
      modules: [[], [ArrayValidators.minLength(1)]],
      email: ['', [Validators.required, Validators.email]]
    });

    this.modules$ = this.store.pipe(select(estimateReducers.getModules));
    this.isLoading$ = this.store.pipe(select(estimateReducers.isLoading));
    this.customAppCost$ = this.store.pipe(select(estimateReducers.getCustomAppCost));
    this.isOfferComputed$ = this.store.pipe(select(estimateReducers.isOfferComputed));

    this.isOfferComputed$.subscribe((isOfferComputed) => {
      if (isOfferComputed) {
        this.deconstructForm.reset({
          email: '',
          description: '',
          modules: []
        })
      }
    })


    this.breakpointObserver.observe([
      "(max-width: 959px)"
    ]).subscribe((result: BreakpointState) => {
      this.isWeb = !result.matches;
    });

    this.store.dispatch(estimateActions.loadModules());

    this.modules$.pipe(filter((app) => !!app), untilDestroyed(this))
      .subscribe((modules: Module[]) => {
        this.modules = modules;
      });

    this.customAppCost$.pipe(filter((app) => !!app), untilDestroyed(this))
      .subscribe((cost: ApplicationCost) => {
        this.customAppCost = cost;
      });
  }

  onModuleClick(module: Module) {
    const selectedModules = this.control('modules').value || [];

    const moduleIndex = selectedModules.findIndex((mod: Module) => mod.id === module.id);

    if (moduleIndex !== -1) {
      // If the module exists, remove it from the array
      selectedModules.splice(moduleIndex, 1);
    } else {
      // If the module does not exist, add it to the array
      selectedModules.push(module);
    }

    this.control('modules').setValue(selectedModules);
  }

  onModulePress(module: Module) {
    this._bottomSheet.open(BottomSheetComponent, {
      panelClass: 'max-width',
      data: {
        module: module,
        app: this.appName,
        custom: true
      }
    });

    /*
    TODO: call for calculating the cost for custom app
    const moduleKeys = this.modules.map(item => item.key);
    this.store.dispatch(estimateActions.calculateCustomAppCost({moduleKeys}));*/
  }

  isModuleSelected(module: Module): boolean {
    const selectedModules = this.control('modules').value;
    return selectedModules.some((mod: Module) => mod.id === module.id);
  }

  control(controlName: string) {
    return this.deconstructForm.get(controlName) as FormControl;
  }

  onSubmit() {
    if (!this.deconstructForm.valid) {
      this.showValidations = true;
      return;
    }
    this.showValidations = false;

    // Map modules for sending them
    const selectedModules: any[] = this.control('modules').value.map((module: Module) => {
      return {
        key: module.key,
        name: this.translate.instant('pages.estimate.modules.' + module.key + '.name'),
        description: this.translate.instant('pages.estimate.modules.' + module.key + '.description'),
        icon: `assets/icons/modules/module_${module.key}_icon.svg`
      }
    })

    const offerRequest = new OfferRequest(
      {
        email: this.control('email').value,
        description: this.control('description').value,
        modules: selectedModules
      }
    );

    this.store.dispatch(estimateActions.submitOffer(
      {
        offerRequest,
      }));
  }
}
