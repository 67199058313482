import { Component, ElementRef, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-multitext',
  templateUrl: './input-multitext.component.html',
  styleUrls: ['./input-multitext.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputMultitextComponent,
      multi: true
    }
  ]
})
export class InputMultitextComponent implements ControlValueAccessor {

  @Input() type = 'text';
  @Input() label = '';
  @Input() formControl: FormControl;
  @Input() showErrors = false;
  @Input() required = false;
  @Input() reactive = false;
  @Input() hintMessage: string;

  /** Limits the amount of chips that can be selected as part of this input **/
  @Input() limit = 0;

  @Output() selectedValuesChange = new EventEmitter<any>();

  inputValue = '';
  selectedValues: string[] = [];

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  writeValue(value: any) {
    this.selectedValues = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onChange(event: any) {
    if (event.target) {
      this.inputValue = event.target.value;
    }
  }

  onAddValue() {
    if (!this.inputValue) {
      return;
    }
    this.selectedValues.push(this.inputValue);
    this.emitSelectedValues();
    this.inputValue = '';
  }

  onKeydownEnter(event: any) {
    this.onAddValue();
    event.stopPropagation();
    return false;
  }

  onRemove(index: number) {
    this.selectedValues.splice(index, 1);
    this.emitSelectedValues();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'disabled', isDisabled + '');
  }

  private emitSelectedValues() {
    if (this.onChangeCallback) {
      this.onChangeCallback(this.selectedValues);
    }
    if (this.onTouchedCallback) {
      this.onTouchedCallback(this.selectedValues);
    }
    this.selectedValuesChange.emit(this.selectedValues);

    this.checkMax();
  }

  private checkMax() {
    if (this.limit && this.limit === this.selectedValues.length) {
      this.setDisabledState?.(true);
    } else {
      this.setDisabledState?.(false);
    }
  }
}
