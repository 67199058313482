import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {EstimateComponent} from './estimate.component';
import {ApplicationDetailsCardComponent, CustomAppCardComponent} from "./components";


const estimateRoutes: Routes = [
  {
    path: '', component: EstimateComponent, children: [
      {path: 'app/custom', component: CustomAppCardComponent},
      {path: 'app/:id', component: ApplicationDetailsCardComponent},
      {path: '**', redirectTo: 'app/uber', pathMatch: 'full'}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(estimateRoutes)],
  exports: [RouterModule],
  providers: []
})
export class EstimateRoutingModule {
}
