import {Feature} from './feature.model';
import {Complexity} from "./complexity.model";

export class Module {
  public id: string;
  public key: string;
  public features: Feature[];
  public complexity: Complexity;

  constructor(module: any = {}) {
    this.id = module?.id || '';
    this.key = module?.key || '';
    this.features = module?.features?.map((feature: any) => new Feature(feature)) || [];
    this.complexity = new Complexity(module?.complexity) || null;
  }
}
