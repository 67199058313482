<!-- TODO: this needs to be properly addressed -->
<ng-container *ngIf="formControl; else noFormControl">
  <mat-checkbox [formControl]="formControl"
                [labelPosition]="labelPosition">
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </mat-checkbox>
</ng-container>

<ng-template #noFormControl>
  <mat-checkbox [labelPosition]="labelPosition"
                [(ngModel)]="checked"
                [disabled]="isDisabled"
                (change)="onChange($event)">
    <ng-container *ngTemplateOutlet="label"></ng-container>
  </mat-checkbox>
</ng-template>

<app-form-error-list *ngIf="showErrors"
                     [errorMap]="formControl.errors">
</app-form-error-list>
