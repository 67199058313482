import {Component, HostBinding} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

import {select, Store} from '@ngrx/store';

import {MatIconRegistry} from '@angular/material/icon';

import {TranslateService} from '@ngx-translate/core';

import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {Theme, UXActions, UXReducer} from '../../libs/ngnizer/ux/src/lib';
import {environment} from '../environments/environment';

declare var gtag: any;

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  get currentLanguage(): string {
    return environment.defaultLanguage;
  }

  static LANGUAGE_KEY = 'user-language';

  @HostBinding('class') classes = '';

  themes: any[] = [
    {
      icon: 'wb_sunny',
      label: 'Light',
      value: Theme.Light
    },
    {
      icon: 'dark_mode',
      label: 'Dark',
      value: Theme.Dark
    }
  ];

  theme$: Observable<Theme>;


  constructor(private store: Store<any>,
              private router: Router,
              private translateService: TranslateService,
              private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private ccService: NgcCookieConsentService) {
    if (environment.production) {
      this.initGoogleAnalytics();
    }

    this.initTranslate();
    this.initCcTranslations();
    this.initIconRegistry();

    this.theme$ = this.store.pipe(select(UXReducer.getTheme));

    this.theme$.pipe(untilDestroyed(this))
      .subscribe(newTheme => {
        this.classes = newTheme;
        const classList = document.body.classList;
        while (classList.length > 0) {
          classList.remove(classList.item(0)!);
        }
        const bodyClasses = ['mat-typography', 'mat-app-background', newTheme];
        document.body.classList.add(...bodyClasses);
      });
  }

  private initTranslate(): void {
    this.translateService.addLangs(['ro', 'en']);
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  private initIconRegistry() {
    this.matIconRegistry.addSvgIcon(
      'google',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/google.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'components',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/components.svg')
    );
  }

  private initCcTranslations() {
    this.translateService.get(['general.cookie.message', 'general.cookie.dismiss', 'general.cookie.link'])
      .subscribe(data => {
        this.ccService!.getConfig().content = this.ccService.getConfig().content || {};
        this.ccService!.getConfig()!.content!.message = data['general.cookie.message'];
        this.ccService!.getConfig()!.content!.dismiss = data['general.cookie.dismiss'];
        this.ccService!.getConfig()!.content!.link = data['general.cookie.link'];
        this.ccService.destroy();
        this.ccService.init(this.ccService.getConfig());
      });
  }

  private initGoogleAnalytics() {
    const navEvents = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navEvents.subscribe((event: any) => {
      gtag('config', 'G-YBHN8RN57K', {
        page_path: event.urlAfterRedirects
      });
    });
  }

  onChangeTheme(option: any) {
    switch (option) {
      case Theme.Light:
        this.store.dispatch(UXActions.selectTheme({
          theme: Theme.Light
        }));
        break;
      case Theme.Dark:
        this.store.dispatch(UXActions.selectTheme({
          theme: Theme.Dark
        }));
        break;
    }
  }
}


