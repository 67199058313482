import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as _ from 'lodash-es';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-input',
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputComponent,
      multi: true
    },
  ],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],

})
export class InputComponent implements ControlValueAccessor {

  @Input()
  set value(value) {
    this.inputValue = value;

    if (this.onChangeCallback) {
      this.onChangeCallback(value);
    }
    this.inputChange.emit(value);
  }

  get value() {
    return this.inputValue;
  }

  @HostBinding('class.editable') get isEditable() {
    return this.editable;
  }

  @HostBinding('class.reactive') get isReactive() {
    return this.reactive;
  }

  get hasError() {
    return this.formControl && this.formControl.errors && this.showValidations;
  }

  @ViewChild('nativeElem', {static: false}) nativeElement: ElementRef<any>;

  @Input() id: any;
  @Input() isDisabled = false;
  @Input() isReadonly = false;
  @Input() type: 'email' | 'text' | 'number' | 'search' | 'password' | 'date' | '' = 'text';
  @Input() label: string;
  @Input() formControl: FormControl;
  @Input() showValidations = false;
  @Input() required = false;
  @Input() reactive = false;
  @Input() forceUpperCase = false;
  @Input() hintMessage: string;
  @Input() prefixIcon: string;

  @Input() startView: 'month' | 'multi-year' | 'year' = 'month';

  @Input() minLength = 0;
  @Input() maxLength = 50;

  @Output() inputChange = new EventEmitter<any>();
  editable = false;

  reveal = false;

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  private inputValue = '';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {

  }

  @HostListener('blur') onBlur() {
    this.editable = false;
  }

  @HostListener('click') onClick() {
    this.editable = true;
    if (this.nativeElement) {
      this.nativeElement.nativeElement.focus();
    }
  }

  writeValue(value: string) {
    this.inputValue = value;
    if (!_.isNil(value)) {
      this.inputChange.emit(value);
    }

  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onChange(event: any) {
    if (event.target) {
      this.inputChange.emit(event.target.value);
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef, 'disabled', isDisabled);
  }

  stepUp() {
    if (this.type === 'number') {
      this.formControl.setValue(+this.formControl.value + 1);
    }
  }

  stepDown() {
    if (this.type === 'number') {
      this.formControl.setValue(+this.formControl.value - 1);
    }
  }

  forceKeyPressUpperCase(event: any) {
    if (this.forceUpperCase) {
      const charInput = event.key;
      if ((charInput >= 'a') && (charInput <= 'z')) {
        if (!event.ctrlKey && !event.metaKey && !event.altKey) {
          const newChar = charInput.charCodeAt(0) - 32;
          const start = event.target.selectionStart;
          const end = event.target.selectionEnd;
          event.target.value = event.target.value.substring(0, start) + String.fromCharCode(newChar) +
            event.target.value.substring(end);
          event.target.setSelectionRange(start + 1, start + 1);
          event.preventDefault();
          if (this.onChangeCallback) {
            this.onChangeCallback(event.target.value);
          }
          this.inputChange.emit(event.target.value);
        }
      }
    }
  }

  get isEmail() {
    return this.type === 'email';
  }

  get isSearch() {
    return this.type === 'search';
  }

  get isNumber() {
    return this.type === 'number';
  }

  get isText() {
    return this.type === 'text';
  }

  get isPassword() {
    return this.type === 'password' || this.type === '';
  }

  get isValid() {
    return this.formControl && this.formControl.valid && this.formControl.touched;
  }

  togglePassword() {
    this.reveal = !this.reveal;
    this.type = this.type === 'password' ? '' : 'password';
  }

  get showErrors() {
    return this.showValidations || (this.formControl?.invalid && this.formControl?.touched && this.formControl?.dirty);
  }
}
